<template>
    <block class="saffron-app">
        <!-- meta service -->
        <metainfo>
            <template v-slot:title="input">
                {{ input.content ? input.content + ' | ' + input.metainfo.sitename.content : input.metainfo.sitename.content}}
            </template>
        </metainfo>

        <!-- bar loader service -->
        <bar-loader v-if="useAsyncLoadBar"
                    :active="asyncStatus.anyLoading"
                    :fixed="true"></bar-loader>


        <block class="saffron-app-inner" v-if="useLayouts">
            <component :is="finalLayout"
                       v-bind="layoutParams">
                <slot></slot>
            </component>
        </block>

        <block class="saffron-app-inner" v-else="useLayouts">
            <slot></slot>
        </block>
<!--      :tick-interval="50*1000"
            :notification-duration="60*10*1000"
            :max-inactivity="60*30*1000"

         -->
        <inactivity-disconnect
       ></inactivity-disconnect>
        <!-- global spinner service -->
        <spinner
                        v-if="useGlobalSpinner"
                        overlay="fixed"
                        :show="globalSpinnerActive"
                        :text="globalSpinnerText"
                    ></spinner>

        <teleport to="body">
            <modal ref="authErrorModal"
                   :title="translate('core.user.clientSideAuthErrorTitle')"
                   :show="showDisconnectedModal">
                <template #default>
                    {{translate('core.user.clientSideAuthErrorExplain') }}
                </template>
                <template #footer>
                    <button class="uk-button uk-button-primary"
                            type="button"
                            @click="acceptDisconnect">{{translate('core.user.clientSideAuthErrorOk') }}</button>
                </template>
            </modal>
        </teleport>
    </block>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import layoutComposition from "@/client/extensions/composition/layout";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n/index";
import {useMeta} from "vue-meta";
import {mapGetters} from "vuex";
import {watchEffect, computed} from 'vue';
export default {
    setup(props) {
        let result = {};

        // meta handling
        if (props.useMeta) {

            let {t} = useI18n();
            let {meta} = useMeta( {
                title:  t('core.meta.defaultTitle'),
              //  htmlAttrs: { lang: 'en', amp: true }, // now working. fuck third party
                description: t('core.meta.defaultDescription'),
                sitename: {tag: 'meta', 'name':'sitename', content: t('core.meta.siteName')},
                charset : {tag:'meta', charset:'utf8'},
                generator : {tag:'meta', content:'Saffron - spice for your code'},
                // TODO: make configurable
                'theme-color': { 'tag': 'meta', 'name' : 'theme-color', content: config.themeColor},
            });
            result = {...result, meta};

        }

        // global async load bar indicator
        if (props.useAsyncLoadBar) {
            let {asyncOps, asyncStatus} = asyncOperations();
            result = {...result, asyncOps, asyncStatus};
        }

        if (props.useLayouts) {
            let {finalLayout, finalLayoutRaw, layoutParams} = layoutComposition(props, useRouter());
            result = {...result, finalLayout, finalLayoutRaw, layoutParams};
        }

        return result;

    },
    props: {
        useLayouts: {
            type: Boolean,
            default: true,
        },
        useAsyncLoadBar: {
            type: Boolean,
            default: true,
        },
        useGlobalSpinner: {
            type: Boolean,
            default: true,
        },
        useMeta : {
            type: Boolean,
            default: true,
        }
    },
    data: function () {
        return {
            isPageRtl : computed(() => {return this.isLanguageRtl();}),
            showDisconnectedModal: false,
            initialUserFetchComplete : this.$store.getters['user/initialFetchComplete']
        };
    },
    computed : {
        ...mapGetters({
            globalSpinnerActive: 'ui/isGlobalSpinnerActive',
            globalSpinnerText: 'ui/globalSpinnerText',
            userAuthError : 'user/authError',
        })
    },
    methods: {
        acceptDisconnect() {
            this.showDisconnectedModal = false;
            this.$router.go();
        },

    },
    serverPrefetch () {
        return new Promise((resolve) => {
            let done = false;
            let timeout;

            // also, dont allow ridicules hanging
            timeout = setTimeout(() => {
                resolve();
            }, 5000);

            // when we get use prefetch - release this hook
            watchEffect(() => {
                if (this.$store.getters['user/initialFetchComplete']) {
                    clearTimeout(timeout);
                    resolve();
                }
            });
        })
    },
    created() {},
    watch: {
        userAuthError(newVal, oldVal) { // on auth error, show modal and logout the user. error is considered handled
            if (newVal && ! oldVal) {
                this.$store.commit('user/setAuthError', false);
                this.$store.commit('user/logout');
                this.showDisconnectedModal = true;
            }
        },
        isPageRtl: {
            handler () {
                if ( utilities.isSSR()) {
                    return;
                }

                if (this.isLanguageRtl()) {
                    document.querySelector('html').setAttribute('dir', 'rtl');
                } else {
                    document.querySelector('html').setAttribute('dir', 'ltr');
                }
            },
            immediate: true
        }
    }

}
</script>

<style scoped lang="scss">

</style>
